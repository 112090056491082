<template>
  <div class="overlay" v-if="isLoading">
    <div class="overlay-inner">
      <div class="overlay-content"><span class="spinner"></span></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/global.scss";
.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 100;
  &-inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
  }

  &-content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinner {
  width: 60px;
  height: 60px;
  display: inline-block;
  border-width: 2px;
  border-color: $pri-color;
  border-top-color: $sec-color;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
