<template>
  <router-view />
  <loading-state />
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import LoadingState from "../src/components/reusables/LoadingState.vue";
export default {
  name: "HomeView",
  components: {
    LoadingState,
  },
  mounted() {
    AOS.init({
      duration: 800,
      once: true,
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
body {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $sec-color;
  background: $bg;
  line-height: 1.5;
  font-size: 16px;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
}
a {
  text-decoration: none;
  color: #2c3e50;
}
input,
textarea {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  outline: none;
  border-radius: 5px;
  border: solid 0.5px $calc-border;
  margin: 0.5rem 0;
  transition: 0.5s ease border;
}
input:focus {
  border: solid 0.5px $sec-color;
}
.input-field {
  margin: 1rem 0;
}
.btn {
  cursor: pointer;
  padding: 0.7rem 1rem;
  display: inline-block;
  margin: 0.5rem 0;
  outline: none;
  border: none;
  color: $white;
  background: $btn;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
}
.container {
  width: 90%;
  overflow: hidden;
  margin: auto;
}
.hide-on-sm {
  display: none;
}
.hide-on-lg {
  display: block;
}
.routes {
  margin-top: 3.5rem;
}
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  line-height: 34px;
}
p {
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 700px) {
  .hide-on-lg {
    display: none;
  }
  .hide-on-sm {
    display: block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 25px;
    line-height: 35px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1000px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 40px;
    line-height: 57px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1400px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 43px;
    line-height: 50.4px;
    font-weight: normal;
  }
  p {
    font-size: 24px;
    line-height: 34px;
  }
  .btn {
    width: 350px;
    height: 56px;
    padding: 11px 31px;
  }
}
</style>
