import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  // Default toast options
  position: "top-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

const app = createApp(App);
app.component("v-select", vSelect);
app.use(store);
app.use(router);
app.use(Toast, options);
app.mount("#app");
