<template>
  <header class="header">
    <div class="container">
      <div class="header-content">
        <router-link to="/"
          ><img src="@/assets/img/logo.svg" alt="logo"
        /></router-link>
        <nav class="nav" :class="{ open: open }">
          <ul>
            <li><router-link to="/about">About Us</router-link></li>
            <li>
              <router-link to="/rate">Rate Calculator</router-link>
            </li>
            <li>
              <router-link to="/contact">Contact Us</router-link>
            </li>
            <li><router-link to="/faq">FAQ</router-link></li>
            <li><router-link to="/blogs">Blog</router-link></li>
            <li><router-link to="/download">Download App</router-link></li>
          </ul>
        </nav>
        <div
          @click="openNav"
          class="hamburger hide-on-lg"
          :class="{ change: open }"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderTag",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openNav() {
      this.open = !this.open;
    },
  },
  mounted() {
    this.$router.beforeEach((from, to, next) => {
      this.open = false;
      next();
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background: $showcase-bg;
  z-index: 100;
  box-shadow: 0px 4px 40px 0px #01013712;
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content h1 {
    color: rgb(0, 45, 95);
  }
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
}
img {
  width: 133px;
  height: 30.51px;
}
.hamburger {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  z-index: 10;
  width: 30px;
  height: 20px;
  & > div {
    height: 2px;
    width: 30px;
    background: $sec-color;
    margin: 0.1rem 0;
    transition: transform ease-in-out 0.4s;
    cursor: pointer;
  }
  & > div:nth-child(1) {
    width: 16px;
  }
  & > div:nth-child(3) {
    width: 20px;
  }
}
.change.hamburger > div:nth-child(1) {
  transform: translate(1px, 5px) rotate(45deg);
  width: 20px;
}
.change.hamburger > div:nth-child(2) {
  opacity: 0;
}
.change.hamburger > div:nth-child(3) {
  transform: translate(0px, -6px) rotate(-45deg);
  width: 20px;
}

.open.nav {
  display: block;
  animation: fade ease-in-out 0.4s forwards;
}
@keyframes fade {
  to {
    opacity: 1;
  }
}
.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background: rgba(39, 52, 72, 0.85);
  background: $showcase-bg;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  transition: ease-in-out 0.4s top;
  display: none;
  opacity: 0;
  text-align: right;
  & ul {
    margin-top: 3rem;
    padding: 1rem;
  }
  & ul li {
    display: block;
    padding: 1rem 0;
    text-transform: capitalize;
    a {
      color: $text-color;
    }
  }
  & ul li:last-child {
    a {
      padding: 12px 20px;
      background: $white;
      border-radius: 5px;
      margin-top: 2rem;
      width: 183px;
      text-align: center;
      font-size: 18px;
      display: inline-block;
      background-color: $sec-color;
      color: $white;
      text-align: center;
    }
    a.router-link-active {
      border: none;
    }
  }
  & a {
    font-weight: normal;
    color: $white;
    text-decoration: none;
  }
  & a.router-link-exact-active {
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: solid 2px $sec-color;
  }
}

@media screen and (min-width: 1049px) {
  .hamburger {
    display: none;
  }
  .nav {
    position: relative;
    width: auto;
    height: auto;
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    z-index: 0;
    top: 0;
    display: block;
    opacity: 1;
    & ul {
      padding: 0;
      margin: 0;
    }
    & ul li {
      display: inline;
      padding: 0 1.5rem 0 0;
    }
    & ul li:last-child {
      a {
        width: auto;
        height: 50px;
        margin: 0 0 0 2rem;
      }
    }
    & a {
      text-decoration: none;
      font-size: 17px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .nav ul li:last-child {
    a {
      width: 200px;
      height: 50px;
      margin: 0 0 0 2rem;
    }
  }
}
@media screen and (min-width: 1400px) {
  a {
    font-size: 20px;
    line-height: 24px;
  }
  .header {
    height: 93px;
    &-content {
      width: 1200px;
      margin: auto;
      & img {
        width: 191px;
        height: 53.6px;
      }
    }
  }
  .nav ul li {
    padding: 0 1rem 0 0;
  }
  .nav ul li:last-child {
    a {
      padding: 1rem;
      margin-left: 5rem;
    }
  }
  .nav a {
    font-size: 18px;
  }
}
</style>
