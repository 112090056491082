<template>
  <section class="airtime">
    <div class="container">
      <div class="section-one">
        <img data-aos="zoom-in" src="@/assets/img/iphone.svg" alt="iphone" />
        <div class="download-app">
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Buy Airtime and Data Instantly
          </h3>
          <p data-aos="fade-up" data-aos-duration="1250">
            Easyflip also allows you to purchase airtime and data quickly and
            conveniently. Whether you need to top up your phone or stay online,
            we've got you covered.
          </p>
          <router-link
            to="/download"
            data-aos="fade-up"
            data-aos-duration="1500"
            class="btn btn-primary"
          >
            Download App
          </router-link>
        </div>
      </div>
      <div class="section-two">
        <div
          class="section-two-content"
          v-for="(list, index) in lists"
          :key="index"
        >
          <img data-aos="zoom-in" :src="list.icon" alt="icon" />
          <h3 data-aos="fade-up" data-aos-duration="1250">{{ list.title }}</h3>
          <p data-aos="fade-up" data-aos-duration="1500">
            {{ list.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import icon1 from "@/assets/img/wide-coverage.svg";
import icon2 from "@/assets/img/easy-to-use.svg";
import icon3 from "@/assets/img/instant-transaction.svg";
import icon4 from "@/assets/img/transaction-payments.svg";
export default {
  data() {
    return {
      lists: [
        {
          icon: icon1,
          title: "Wide Coverage",
          description:
            "We support all major networks, ensuring you're always connected",
        },
        {
          icon: icon2,
          title: "Easy to Use",
          description:
            "Our user-friendly interface makes buying airtime and data a breeze.",
        },
        {
          icon: icon3,
          title: "Instant Transactions",
          description:
            "Get your airtime and data in seconds. No waiting, no delays in your transactions.",
        },
        {
          icon: icon4,
          title: "Secure Payments",
          description:
            "Your transactions are safe and encrypted, giving you peace of mind.",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";

.section-one {
  & .download-app {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    max-width: 550px;

    & h3 {
      font-size: 20px;
      font-weight: 600;
    }

    & p {
      font-size: 14px;
      color: #3e3e45;
    }

    & .btn {
      width: fit-content;
      padding: 1rem 4rem;
      margin-top: 1rem;
    }
  }
}

.section-two {
  display: grid;
  gap: 2rem;
  padding-top: 2rem;

  &-content {
    background-color: #f8f8ff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & img {
      width: 40px;
      height: 40px;
    }

    & h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
    }

    & p {
      font-size: 16px;
      color: #3e3e45;
    }
  }
}

@media screen and (min-width: 700px) {
  .section-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1000px) {
  .section-one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: #f8f8ff;
    padding-top: 1rem;

    & img {
      width: auto;
    }

    & .download-app {
      & h3 {
        font-size: 32px;
      }

      & p {
        font-size: 18px;
        color: #3e3e45;
      }
    }
  }

  .section-two {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
