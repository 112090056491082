import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/rate",
    name: "rate",
    component: () =>
      import(/* webpackChunkName: "rate" */ "../views/CalcView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/policy",
    name: "policy",
    component: () =>
      import(/* webpackChunkName: "policy" */ "../views/PolicyView.vue"),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "blogs" */ "../views/BlogView.vue"),
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/SingleBlog.vue"),
    props: true,
  },
  {
    path: "/terms-of-use",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/aml-policy",
    name: "aml-policy",
    component: () =>
      import(/* webpackChunkName: "aml-policy" */ "../views/AmlView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignupView.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/DownloadView.vue"),
  },
  {
    path: "/delete-account",
    name: "delete-acccount",
    component: () =>
      import(
        /* webpackChunkName: "delete-account" */ "../views/DeleteAccountView.vue"
      ),
  },
  {
    path: "/delete-account-reason",
    name: "delete-acccount-reason",
    component: () =>
      import(
        /* webpackChunkName: "delete-account-reason" */ "../views/DeleteAccountReasonView.vue"
      ),
  },
  {
    path: "/account-activation",
    name: "activated",
    component: () =>
      import(
        /* webpackChunkName: "activated" */ "../components/mailing/VerifiedMail.vue"
      ),
  },
  {
    path: "/NotFound",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
