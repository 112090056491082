<template>
  <section class="trading-benefits">
    <div class="container">
      <div class="trading-benefits-content">
        <div class="section-one">
          <div class="section-one-content">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Instant Trading at Your Convenience
            </h2>
            <p data-aos="fade-up" data-aos-duration="1250">
              Buy and sell giftcards from over 20 countries around the world at
              amazing rates and start trading seamlessy.
            </p>
            <router-link
              to="/download"
              data-aos="fade-up"
              data-aos-duration="1500"
              class="btn"
            >
              Download App
            </router-link>
          </div>
          <img
            class="section-one-icon"
            src="/img/easyflip-icon.svg"
            alt="Easyflip icon"
          />
          <img
            class="section-one-ellipse"
            src="/img/design-ellipse.svg"
            alt="Design Ellipse"
          />
        </div>
        <div class="section-two">
          <h3 data-aos="fade-up" data-aos-duration="1000">Top Benefits</h3>
          <div
            class="section-two-content"
            v-for="(list, index) in benefits"
            :key="index"
            data-aos="fade-up"
            data-aos-duration="1250"
          >
            <img src="/img/diamond.svg" alt="diamond" />
            <p>{{ list }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      benefits: [
        "Secure Transactions",
        "Instant Trading",
        "Fast Payouts",
        "Real-Time Rates",
        "User-Friendly Interface",
        "Reliable Customer Support",
        "Reward Programs",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.trading-benefits-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding-bottom: 2rem;

  & .section-one {
    background-color: #010137;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    color: $white;

    &-icon {
      width: 150px;
      height: 150px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-ellipse {
      width: 125px;
      height: 83px;
      position: absolute;
      top: 0;
      right: 5rem;
    }

    &-content {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      max-width: 477px;
      gap: 1rem;

      & h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
      }

      & .btn {
        border-radius: 10px;
        max-width: 241px;
        text-align: center;
        border-radius: 8px;
        background-color: $white;
        color: #010137;
      }
    }
  }

  & .section-two {
    background-color: #f6f6ff;
    border-radius: 16px;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    & h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
    }

    &-content {
      display: flex;
      gap: 1rem;
      align-items: center;

      & img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .trading-benefits-content {
    & .section-one {
      &-icon {
        width: 300px;
        height: 300px;
        position: absolute;
      }

      &-ellipse {
        right: 10rem;
      }

      &-content {
        & h2 {
          font-size: 25px;
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .trading-benefits-content {
    display: flex;
    & .section-one {
      flex-basis: 65%;
      border-radius: 20px;

      &-icon {
        width: 480px;
        height: 480px;
        position: absolute;
        top: -20px;
        right: -100px;
      }

      &-ellipse {
        right: 8rem;
        width: 200px;
      }

      &-content {
        padding: 50px;
        gap: 2rem;
        & h2 {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
    & .section-two {
      flex-basis: 35%;
    }
  }
}
@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
