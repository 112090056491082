<template>
  <div>
    <main-section />
    <choose-us />
    <!-- <app-steps /> -->
    <seamless-trading />
    <airtime-purchase />
    <!-- <aside-main /> -->
    <explore-possibilities />
    <create-account-steps />
    <trading-benefits />
    <download-app />
    <!-- <start-trading /> -->
    <Footer />
  </div>
</template>
<script>
import MainSection from "@/components/home/MainSection.vue";
import ChooseUs from "@/components/home/ChooseUs.vue";
// import AppSteps from "@/components/home/AppSteps.vue";
import SeamlessTrading from "@/components/home/SeamlessTrading.vue";
import AirtimePurchase from "@/components/home/AirtimePurchase.vue";
import ExplorePossibilities from "@/components/home/ExplorePossibilities.vue";
// import AsideMain from "@/components/home/AsideMain.vue";
import CreateAccountSteps from "@/components/home/CreateAccountSteps.vue";
import TradingBenefits from "@/components/home/TradingBenefits.vue";
import DownloadApp from "@/components/home/DownloadApp.vue";
// import StartTrading from "@/components/home/StartTrading.vue";
import Footer from "@/components/reusables/Footer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    MainSection,
    ChooseUs,
    SeamlessTrading,
    AirtimePurchase,
    ExplorePossibilities,
    CreateAccountSteps,
    TradingBenefits,
    DownloadApp,
    Footer,
  },
  methods: {
    ...mapMutations(["resetModal"]),
  },
  mounted() {
    this.resetModal();
  },
};
</script>
<style lang="scss" scoped></style>
