<template>
  <section class="explore-possibilities">
    <div class="container">
      <div class="section">
        <h1 class="title" data-aos="fade-up">
          Explore the possibilities that await you on Easyflip.
        </h1>
        <div class="cards">
          <div
            class="card"
            v-for="(card, index) in cards"
            :key="index"
            data-aos="zoom-in"
          >
            <img :src="`/img/${card.icon}`" :alt="`${card.id}`" />
            <div>
              <h1>{{ card.title }}</h1>
              <p>{{ card.content }}</p>
            </div>
          </div>
          <div class="h-line"></div>
          <div class="v-line"></div>
        </div>
        <router-link to="/download">
          <div class="btn" data-aos="fade-up">Download App</div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        {
          id: "01",
          icon: "icon-01.svg",
          title: "Trade Giftcards",
          content:
            "Transform unused gift cards into valuable assets. Trade them for what you need, effortlessly.",
        },
        {
          id: "02",
          icon: "icon-02.svg",
          title: "Trade Digital Assets",
          content:
            "Experience seamless digital asset trading. We provide the ideal environment for a successful trading.",
        },
        {
          id: "03",
          icon: "icon-03.svg",
          title: "Buy Airtime And Data",
          content:
            "Stay connected with ease. Recharge your mobile phone's airtime and data using our platform.",
        },
        {
          id: "04",
          icon: "icon-04.svg",
          title: "Earn through Referrals",
          content:
            "Boost your earnings through referrals. When people sign up with your referral link, you will earn rewards.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.explore-possibilities {
  position: relative;
  background-color: $white;
  padding: 3rem 0;

  .section {
    .title {
      font-size: 20px;
      text-align: center;
      padding: 0 0 1rem;
    }
    .cards {
      .card {
        display: flex;
        gap: 1rem;
        align-items: center;
        background: $white;
        // padding: 1.5rem;
        // border-radius: 2.5rem 1rem;
        margin: 1.5rem 0.1rem;
        & h1 {
          color: $sec-color;
          font-weight: 700;
          font-size: 14px;
          padding: 0.5rem 0;
          line-height: 30px;
        }
        & p {
          color: #65656a;
          font-size: 12px;
        }
        & img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .btn {
      background-color: $btn;
      color: $white;
      display: block;
      padding: 1rem 4rem;
      margin: 2rem auto 0;
      justify-content: center;
      text-align: center;
      border-radius: 0.5rem;
      width: fit-content;
    }
  }
}

@media screen and (min-width: 700px) {
  .explore-possibilities {
    padding: 5rem 0;

    .section {
      .title {
        font-size: 25px;
        // padding: 0 0 1rem;
      }
      .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 2rem;
        position: relative;
        margin: 20px 0 40px;
        .card {
          & h1 {
            font-size: 20px;
            font-weight: 700;
            padding-bottom: 10px;
          }
          & p {
            color: #65656a;
            font-size: 16px;
          }
          & img {
            width: 60px;
            height: 60px;
          }
          &:nth-child(2),
          &:nth-child(4) {
            margin-right: auto;
            margin-left: auto;
          }
        }
        .h-line {
          border: 0.7px solid #e7e7e7;
          width: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
        .v-line {
          border: 0.7px solid #e7e7e7;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%);
          height: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .explore-possibilities {
    .section {
      .title {
        font-size: 30px;
      }
      .cards {
        // width: 900px;
        margin: auto;
        .card {
          // width: 350px;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .explore-possibilities {
    .section {
      .title {
        font-size: 32px;
        margin: auto;
        font-weight: 700;
        line-height: 48px;
        padding-bottom: 2rem;
      }
      .cards {
        // width: 1092px;
        margin: auto;
        .card {
          width: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
