<template>
  <section class="main-section">
    <Header />
    <div class="container">
      <div class="section-content routes">
        <div class="section-text">
          <div class="cta">
            <h1 data-aos="fade-up" data-aos-anchor-placement="top-bottom">
              Your Portal To Effortless Trading
            </h1>
            <p data-aos="fade" data-aos-delay="500">
              Beyond providing a seamless platform, we guide you towards
              expertise, ensuring maximum value from every giftcard transaction.
            </p>
          </div>
          <!-- <get-app class="app" /> -->
          <div
            class="app"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="500"
            data-aos-offset="0"
          >
            <a
              href="https://apps.apple.com/ng/app/easyflip-flip-cards/id1605670456"
            >
              <img src="/img/app-store.svg" alt="google-play" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.easyflipMobile&hl=en&gl=US"
            >
              <img src="/img/blacknwhite-google-play.svg" alt="google-play" />
            </a>
          </div>
        </div>
        <div class="illus" data-aos="zoom-in">
          <!-- <gift-cards /> -->
          <img class="sm-view" src="/img/new-mobile-phone.svg" alt="iphone" />
          <img class="lg-view" src="/img/new-iphone.svg" alt="iphone" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Header from "@/components/navbar/Header.vue";
// import GetApp from "@/components/home/GetApp.vue";
// import GiftCards from "@/components/home/GiftCards.vue";
export default {
  components: {
    Header,
    // GetApp,
    // GiftCards,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.main-section {
  background-color: $btn;
  display: flex;
  justify-content: center;
  align-items: center;
  & h3 {
    font-size: 20px;
    line-height: 34px;
  }
}
.section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 3rem 0 0;
  gap: 5rem;
  color: white;
  & .section-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  & h1 {
    margin-bottom: 1rem;
    font-weight: 600;
  }
  & p {
    color: #eeeeee;
  }
  & .app {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-right: auto;

    & img {
      width: 96px;
      height: 29px;
      cursor: pointer;
    }
  }

  .illus {
    & img {
      margin: auto;
    }
    .lg-view {
      display: none;
    }
    .sm-view {
      margin-bottom: -1rem;
    }
  }
}
@media screen and (min-width: 700px) {
  .main-section {
    background-color: $btn;
    & h3 {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .section-content {
    height: calc(100% - 60px);
    position: relative;
    & h1 {
      padding: 3rem 0 0;
      font-size: 30px;
    }
    & p {
      margin: auto;
    }
    & .app {
      & img {
        width: auto;
        height: auto;
      }
    }
  }
  .btn.hide-on-lg {
    display: none;
  }
  .section-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1000px) {
  .section-content {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    height: calc(100vh - 60px);
    & .section-text {
      align-items: flex-start;
      flex-basis: 50%;
    }
    & h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
      color: white;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
    }
    .illus {
      position: absolute;
      bottom: -5rem;
      right: 0;
      .lg-view {
        display: block;
        height: 100vh;
        width: 500px;
      }
      .sm-view {
        display: none;
      }
    }
  }
  .section-title {
    font-weight: 700;
  }
}
@media screen and (min-width: 1280px) {
  .section-content .illus .lg-view {
    height: 100vh;
    width: 600px;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
    margin: auto;
  }
  .section-title {
    line-height: 24px;
    font-size: 14px;
  }

  .section-content {
    & h1 {
      font-size: 48px;
      line-height: 72px;
      font-weight: 600;
    }
    & p {
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
</style>
