<template>
  <section class="createAccountSteps">
    <div class="container">
      <div class="section">
        <div class="begin-step">
          <h1 class="title" data-aos="fade-up" data-aos-duration="1000">
            Start Trading on Easyflip In 3 Simple Steps
          </h1>
          <p data-aos="fade-up" data-aos-duration="1250">
            Experience the convenience of our mobile app in just three simple
            steps.
          </p>
        </div>
        <div class="cards">
          <div
            class="card"
            v-for="(card, index) in cards"
            :key="index"
            data-aos="zoom-in"
          >
            <div class="circle">
              <span>{{ card.id }}</span>
            </div>
            <h1>{{ card.title }}</h1>
            <img :src="`/img/${card.img}`" :alt="`${card.id}`" />
            <p>{{ card.content }}</p>
          </div>
        </div>
        <router-link
          to="/download"
          data-aos="fade-up"
          data-aos-duration="1500"
          class="btn btn-primary"
        >
          Download App
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        {
          id: "1",
          img: "iphone-01.svg",
          title: "Create An Account",
          content:
            "Provide your full name, valid email, phone number, and secure password.",
        },
        {
          id: "2",
          img: "iphone-02.svg",
          title: "Add Your Bank Details",
          content:
            "To make withdrawal on easyflip easy, add your bank details and create a transaction pin only known to you.",
        },
        {
          id: "3",
          img: "iphone-03.svg",
          title: "Start Trading",
          content:
            "Voila! Start experiencing a world of seamless trading and make the most of your assets.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.createAccountSteps {
  background-color: $white;
  padding: 3rem 0rem;
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .begin-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      .title {
        font-weight: 700;
        color: $sec-color;
        font-size: 20px;
      }
      & p {
        color: $text-color;
        max-width: 477px;
      }
      .btn {
        display: block;
        border-radius: 10px;
        text-align: center;
        max-width: 250px;
        margin: 1.5rem 0;
        letter-spacing: normal;
        color: $white;
      }
    }
    .cards {
      padding: 1rem 0;
      display: grid;
      gap: 0.5rem;

      .card {
        background: $white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        text-align: center;

        & h1 {
          color: $sec-color;
          font-weight: 700;
          font-size: 16px;
        }
        & p {
          color: #65656a;
          font-size: 14px;
        }
        & img {
          width: auto;
          margin: auto;
        }
        .circle {
          background-color: #d45d581a;
          box-shadow: 0px 2.800856590270996px 28.008563995361328px 0px #0101370a;
          border: 0.4rem solid $white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          color: #d45d58;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .btn {
      width: fit-content;
      padding: 1rem 4rem;
      margin-top: 2rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .createAccountSteps {
    padding: 5rem 0;
    .section {
      .begin-step {
        text-align: center;
        .title {
          font-size: 25px;
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
}
@media screen and (min-width: 1200px) {
  .createAccountSteps {
    .section {
      .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .card {
          & h1 {
            color: $sec-color;
            font-weight: 700;
            font-size: 20px;
          }
          & p {
            font-size: 16px;
          }
          & img {
            width: 320px;
          }
          .circle {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
