<template>
  <section class="choose-us">
    <div class="container">
      <div class="choose-content">
        <div class="item" data-aos="fade-up">
          <h2 class="title">What Sets Us Apart</h2>
          <p class="item-body" data-aos="fade">
            With easyflip, your trading experience will be exceptional. Here is
            what makes us stand out.
          </p>
        </div>
        <div class="cards">
          <div
            data-aos="zoom-in"
            class="card"
            v-for="(card, index) in cards"
            :key="index"
            :style="`background: ${card.bg}`"
          >
            <h1 class="card-title" :style="`color: ${card.textColor}`">
              {{ card.title }}
            </h1>
            <p>{{ card.description }}</p>
            <img
              class="card-img"
              :src="`/img/${card.icon}`"
              :alt="`${card.icon}`"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        {
          icon: "swift-transactions.svg",
          title: "Swift Transactions",
          description:
            "We employ cutting-edge protocols for every transaction, ensuring swift payments reaching you within minutes.",
          bg: "#F7F7FF",
          textColor: "#010137",
        },
        {
          icon: "unparalled-security.svg",
          title: "Unparalled Security",
          description:
            "Experience unmatched peace of mind with our platform's advanced security measures that has gained the the trust of millions.",
          bg: "#F6FBFF",
          textColor: "#0073C6",
        },
        {
          icon: "user-friendly.svg",
          title: "User Friendly",
          description:
            "Crafted with your needs in focus, our user friendly services guarantee seamless experiences at the most competitive rates.",
          bg: "#FFF8F7",
          textColor: "#D45D58",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/global.scss";
.title {
  padding: 10px 0;
}
.choose-us {
  position: relative;
  background-color: white;
  padding: 3rem 0;
  .item {
    text-align: center;
    & h2 {
      font-weight: 700;
      font-size: 20px;
    }
    &-body {
      color: $text-color;
      font-size: 16px;
    }
  }
  .cards {
    display: grid;
    gap: 24px;
    text-align: center;
    padding: 24px 0;
    .card {
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      & h1 {
        line-height: 21px;
        font-weight: 600;
        font-size: 16px;
      }
      & p {
        font-size: 14px;
        line-height: 21px;
        color: #65656a;
      }
      &-img {
        width: 156px;
        height: 141px;
        margin: auto;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .choose-us {
    .item {
      & h2 {
        font-weight: 700;
        font-size: 32px;
      }
      &-body {
        font-size: 18px;
      }
    }
  }
  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    .card {
      padding: 24px;
      & h1 {
        line-height: 30px;
        font-size: 20px;
      }
      & p {
        font-size: 16px;
        line-height: 24px;
      }
      &-img {
        width: 188px;
        height: 171px;
        margin: auto;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
