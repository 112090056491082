<template>
  <section class="container">
    <div class="download-app">
      <div class="download-app-content">
        <div class="experience">
          <h1 data-aos="fade-up" data-aos-duration="1000">Download The App</h1>
          <p data-aos="fade-up" data-aos-duration="1250">
            Ready to experience the future of finance at your fingertips?
            Download our app now and start trading.
          </p>
          <div class="app" data-aos="fade-up" data-aos-duration="1500">
            <a
              href="https://apps.apple.com/ng/app/easyflip-flip-cards/id1605670456"
            >
              <img src="/img/app-store.svg" alt="app store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.easyflipMobile&hl=en&gl=US"
            >
              <img src="/img/google-play.svg" alt="google play"
            /></a>
          </div>
        </div>
        <div class="trading">
          <h1 data-aos="fade-up" data-aos-duration="1000">
            Ready to start trading?
          </h1>
          <p data-aos="fade-up" data-aos-duration="1250">
            Buy and sell giftcards from over 20 countries around the world at
            amazing rates and start trading seamlessy.
          </p>
          <router-link
            to="/download"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <button class="trad-btn">Download App</button>
          </router-link>
        </div>
        <img
          data-aos="zoom-in"
          class="download-iphone"
          src="/img/new-download-app-iphone.svg"
          alt="iphone"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.download-app {
  margin: 2rem auto;
  &-content {
    padding: 1.5rem 1.5rem 0;
    background: #f4f4ff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    & .download-iphone {
      margin: auto;
    }
    .experience {
      display: none;
      text-align: center;
      flex-direction: column;
      gap: 1.2rem;
      & h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
      & p {
        font-size: 14px;
        line-height: 21px;
      }
    }
    & .trading {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      & h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
      & p {
        font-size: 14px;
        line-height: 21px;
      }
      & .trad-btn {
        max-width: 241px;
        width: 180px;
        margin: 0 auto;
        height: 48px;
        color: $white;
        background-color: $sec-color;
        font-size: 14px;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .download-app {
    margin: 2.5rem auto;
    &-content {
      padding: 2rem 2rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
      position: relative;
      height: 400px;
      align-items: center;

      & .download-iphone {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 300px;
        width: 300px;
        grid-column: 1;
        grid-row: 1;
      }
      & .experience {
        display: none;
      }
      & .trading,
      .experience {
        grid-column: 2;
        grid-row: 1;
        text-align: left;
        gap: 1.2rem;
        & h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 40.4px;
        }
        & p {
          font-size: 16px;
          line-height: 24px;
        }
        & .trad-btn {
          width: 241px;
          margin: 0;
          height: 48px;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .download-app {
    margin: 2.5rem auto;
    &-content {
      & .download-iphone {
        position: absolute;
        bottom: 0;
        left: 0px;
        height: 300px;
        width: 400px;
        grid-column: 1;
        grid-row: 1;
      }
      & .trading {
        display: none;
      }

      & .experience {
        display: grid;
        grid-column: 2;
        grid-row: 1;
        text-align: left;
        gap: 1.2rem;
        & h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 40.4px;
        }
        & p {
          font-size: 16px;
          line-height: 24px;
        }
        & .app {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0;
          & img {
            width: 148.5px;
            height: 45px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .download-app {
    margin: 2.5rem auto;
    &-content {
      & .download-iphone {
        position: absolute;
        bottom: 0;
        left: 0px;
        height: 300px;
        width: 400px;
        grid-column: 1;
        grid-row: 1;
      }
      & .trading {
        display: none;
      }

      & .experience {
        display: grid;
        grid-column: 2;
        grid-row: 1;
        text-align: left;
        gap: 1.2rem;
        & h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 40.4px;
        }
        & p {
          font-size: 16px;
          line-height: 24px;
        }
        & .app {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0;
          & img {
            width: 148.5px;
            height: 45px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
</style>
