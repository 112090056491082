<template>
  <section class="seamless">
    <div class="container">
      <div class="seamless-content">
        <div class="item-one">
          <div class="seamless-head">
            <h2 data-aos="fade-up">Enjoy Seamless Trading</h2>
            <p data-aos="fade-up">
              Convert your giftcards into cash quickly and securely, making the
              most out of your assets at amazing rates enough to put a smile on
              your face.
            </p>
            <div class="lists">
              <div
                v-for="(list, index) in lists"
                :key="index"
                class="list"
                data-aos="fade-up"
              >
                <img :src="`/img/${list.dot}`" :alt="`${list.dot}`" />
                <p>{{ list.context }}</p>
              </div>
            </div>
            <router-link to="/download" class="btn" data-aos="fade-up"
              >Download App</router-link
            >
          </div>
        </div>
        <div class="img">
          <img
            src="/img/new-seamless.svg"
            alt="illustration_02"
            data-aos="zoom-in"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      lists: [
        {
          dot: "dot.svg",
          context:
            "Easily compare rates for different digital assets and gift cards.",
        },
        {
          dot: "dot.svg",
          context: "Your withdrawal requests are processed quickly.",
        },
        {
          dot: "dot.svg",
          context: "Secure the top spot on our leaderboard and win cash prices",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/global.scss";
.seamless {
  padding: 3rem 0;
  & .img {
    margin: 2rem 0;
  }
  &-content {
    & .item-one h2 {
      margin-bottom: 1rem;
    }
  }
  .seamless-head {
    & p {
      color: #65656a;
    }
    .lists {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
      .list {
        display: flex;
        align-items: center;
        gap: 15px;
        & img {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
  .item-one .btn {
    display: block;
    border-radius: 10px;
    text-align: center;
    margin-top: 1rem;
    padding: 1rem 4rem;
    & a {
      color: $white;
    }
  }
}
@media screen and (min-width: 700px) {
  .seamless-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 5rem;
    & h2 {
      font-size: 25px;
      font-weight: 700;
    }
    & p:first-child {
      font-size: 20px;
    }
  }
  .seamless {
    padding: 5rem 0;
    & .btn {
      width: 250px;
      margin: 2rem 0 1rem;
    }
    & .img {
      margin: auto;
      max-width: 370px;
    }
  }
}
@media screen and (min-width: 1000px) {
  .seamless-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    .item-one {
      & h2 {
        font-size: 30px;
      }
    }
    & p {
      font-size: 18px;
    }
  }
  .seamless {
    & .btn {
      width: 250px;
      font-weight: 500;
    }
  }
}
@media screen and (min-width: 1400px) {
  .container {
    width: 1200px;
  }
  .seamless-content {
    justify-content: center;
    .item-one {
      & h2 {
        font-size: 36px;
        line-height: 24px;
        margin-bottom: 1.5rem;
      }
    }
  }
  .seamless .btn {
    width: 350px;
    height: 56px;
    padding: 11px 31px;
  }
}
</style>
